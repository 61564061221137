import * as React from 'react';
import styled from 'styled-components';

import { animated } from 'react-spring';
import { useMutation } from '@apollo/client';

import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';

import { SiteContext } from '../../../utils/context/SiteContext';
import { useForm } from '../../../utils/hooks/useForm';
import { Seo } from '../../../utils/seo/Index';

import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { Thanks } from '../../layout/Thanks';

import { FormError } from '../../ui/form/FormError';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Form, FormRow } from '../../ui/form/Form';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { useLocation } from 'react-router';
import { BackgroundLoad } from '../../layout/BackgroundLoad';

const DigitalMeeting: React.FC = () => {
  const location = useLocation();

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  return (
    <>
      <Seo
        title="Book et digitalt møte"
        description="Selge eiendom, book et uforpliktende møte med PrivatMegleren og få gode råd for prosessen."
        url={location.pathname}
      />
      <Container style={{ ...pageFadeIn, paddingTop: '100px' }}>
        <Section className="form-section">
          <Heading tag="h1" center={true}>
            Book et digitalt møte
          </Heading>
          <Paragraph center={true}>
            Vi vet det er mange spørsmål i forbindelse med å selge eiendom.
            Eiendomsselger lurer på ulike ting ved sin egen eiendom og ønsker
            ofte å orientere seg om eventuell salgsprosess og hva som vil være
            viktig for at eiendommen skal få full uttelling for sitt potensiale
            i markedet.
          </Paragraph>
          <Paragraph center={true}>
            Book et digitalt møte hvor vi snakker om ditt eiendomssalg, uten
            forpliktelse.
          </Paragraph>
          <Paragraph center={true}>Fyll inn skjema under</Paragraph>
        </Section>
        <FormSection />
      </Container>
      <BackgroundLoad
        height={'70vh'}
        opacity={0.6}
        showOnMobile={true}
        position={'top center'}
        url="https://cdn.reeltime.no/pm_assets/digitalt_mote/digitalt_mote.jpg"
      />
    </>
  );
};

export const FormSection: React.FC = () => {
  const { routeConfig }: any = React.useContext(SiteContext);
  const [posted, setPosted] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
            success
          }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 0000',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        placeholder: 'Skriv kommentar',
        value: '',
        label: 'Kommentar'
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      mail({
        variables: {
          input: {
            ...preparedFields,
            referer: window.location.href,
            leadType: 'EPOST',
            mailType: 13
          }
        }
      });
    }
  });

  return (
    <>
      <FormWrapper style={fadeOut}>
        <Form
          onSubmit={handleSubmit}
          noValidate
          style={{ paddingBottom: '50px' }}
        >
          {error &&
            error.graphQLErrors.map((error, index) => (
              <FormError key={`error_${index}`} message={error.message} />
            ))}
          {fields
            .filter((item) => item?.type !== 'textarea')
            .map((item, index) => {
              return (
                <FormRow key={`field_${item.name}`}>
                  <Input
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    labelAfter={item.labelAfter}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={handleChange}
                    error={item.error}
                  />
                </FormRow>
              );
            })}
          {fields
            .filter((item) => item?.type === 'textarea')
            .map((item) => (
              <FormRow key={`field_${item.name}`}>
                <Textarea
                  name={item?.name}
                  label={item?.label}
                  labelAfter={item?.labelAfter}
                  placeholder={item?.placeholder}
                  value={item?.value}
                  onChange={handleChange}
                  error={item?.error}
                />
              </FormRow>
            ))}
          <ButtonGroup>
            <Button
              type="primary"
              colorTheme="gold"
              disabled={loading}
              loading={loading}
            >
              Kontakt meg
            </Button>
            <ButtonLink
              as="a"
              href={routeConfig.personvern.url}
              className="privacy-link-mobile"
            >
              Personvernpolicy
            </ButtonLink>
          </ButtonGroup>
        </Form>
      </FormWrapper>
      <Thanks style={fadeIn}>
        <Section className="form-section">
          <Heading tag="h2" center={true}>
            Vi vet det er mange spørsmål i forbindelse med å selge eiendom. Book
            et digitalt møte hvor vi snakker om ditt eiendomssalg, uten
            forpliktelser.
          </Heading>
          <Paragraph center={true}>
            En eiendomsmegler vil ta kontakt med deg så snart som mulig.
          </Paragraph>
        </Section>
      </Thanks>
    </>
  );
};

export default DigitalMeeting;

const HeroContent = styled(animated.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 0 30px;

  h1 {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
  }

  h2 {
    width: 100%;
    word-break: break-word;
  }
`;

const StyledTop = styled(animated.div)`
  margin-top: 66px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 3em;

  img {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    object-position: center;
  }

  .video-mobil {
    display: block;
  }
  .video-desktop {
    display: none;
  }

  @media (min-width: 600px) {
    .video-mobil {
      display: none;
    }
    .video-desktop {
      display: block;
    }
  }

  @media all and (max-width: 1280px) {
    img {
      min-height: 300px;
      object-fit: cover;
    }
  }
`;

const FormWrapper = styled(animated.div)``;
