import * as React from 'react';
import styled from 'styled-components';

interface ITextarea {
  className?: string;
  name?: string;
  label?: string;
  labelAfter?: string;
  placeholder?: string;
  value?: string | number;
  error?: boolean;
  disabled?: boolean;
  rows?: number;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

export const Textarea: React.FC<ITextarea> = React.memo((props) => {
  return (
    <StyledTextareaWrapper className={props.className}>
      {props.label ? (
        <label>
          {props.label}{' '}
          {props.labelAfter ? <span>{props.labelAfter}</span> : null}
        </label>
      ) : null}
      <StyledTextarea
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        error={props.error}
        rows={props.rows ? props.rows : 10}
        disabled={props.disabled ? 'disabled' : ''}
      />
    </StyledTextareaWrapper>
  );
});

const StyledTextareaWrapper = styled.div`
  width: 100%;

  label {
    display: block;
    font-size: 1rem;
    color: ${(props) => props.theme.input.label};
    margin-bottom: 10px;

    span {
      color: ${(props) => props.theme.input.labelAfter};
    }
  }
`;

const StyledTextarea: any = styled.textarea`
  display: block;
  padding: 15px;
  width: 100%;
  background: ${(props: any) => props.theme.input.background};
  font-size: ${(props) => props.theme.input.font.size};
  font-weight: 400;
  color: white;
  border: ${(props: any) =>
    props.error
      ? `solid 2px ${props.theme.input.error}`
      : 'solid 2px transparent'};
  outline: none;
  resize: none;
  border-radius: 0;

  &::-webkit-input-placeholder {
    color: ${(props: any) => props.theme.input.placeholder};
    font-weight: 400;
    font-size: ${(props) => props.theme.input.font.size};
  }
`;
